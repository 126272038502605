import React, { useState }  from "react";
import styles from "../tiles.module.scss";
import Chevron from "../../../../components/chevron/Chevron";
import * as utils from '../../../../utils/utils';
import CXBarChartSm from '../../../../components/chart-sm/Chart-sm';
//import { ConsoleView } from "react-device-detect";
//import { CreateScoreLinks } from '../../../score/Score.utils';

const KPIScore = (props) => {
    const { kpi, kpiIndex } = props;  

     const kpiItem = kpi.find((item) => item.id === kpiIndex)

    const {id, name, score, lastQuarterValue, quarterly, difference, kpiKeys, kpiValues, dataPoints} = kpiItem

    console.log("My KPI-- New KPI Item*------",kpiItem);
    console.log("My KPI keys and values",kpiKeys, kpiValues);

    const getKPITileNames = (kpiName) =>{
        let tileName;
        let kpi = kpiName.split(" ").join("").toLowerCase();
        switch(kpi){
            case 'ardisputeresolutionindex': tileName = 'AR Dispute Index';
            break;
            case 'availabilityindex': tileName = 'Availability Index';
            break;
            case 'heldorderindex': tileName = 'Held Order Index';
            break;
            case 'invoicingindex': tileName = 'Invoicing Index';
            break;
            case 'perfectorderindex': tileName = 'Perfect Order Index';
            break;
            case 'totaladmincomplaints': tileName = 'Admin Complaints';
            break;
            default: break;
        }  

        return tileName;
    }
    
    // const list = CreateScoreLinks(props);
    // const [ascending, setAscending] = useState(false)
    // const [myList, setMyList] = useState(
    //     [...list].sort(
    //         utils.sorting("score", "number", ascending)
    //     )
    // );

    
    //const [setActive, setActiveState] = useState("active");
    //const [setRotate, setRotateState] = useState("accordion__icon rotate");
//    setRotateState(
//     setActive === "active" ? "accordion__icon rotate" : "accordion__icon"
//   );
    return (
        <div
            className={styles.kpiCardContainer}
            onClick={() => props.viewScore('kpi',kpiItem)}
        >
            <div className={styles.kpiCardHeader}>
                {/*<div className={styles.cardTitle}>CX Score</div>*/}
                { (dataPoints !== undefined)?
                <div className={styles.nValue}>{/*<span className={styles.tooltipForCX}>Total number of order lines considered for calculation of overall Score in this quarter</span>*/}n = { utils.convertToInternationalCurrencySystem(dataPoints)}</div>:''
                }
            </div>
            <div className={styles.kpiCardBody}>
                <div className={styles.cardTitle}>{`${getKPITileNames(name)}`}</div>
                <div className={styles.progressValBody}>
                    {
                        !score ? `-` : <span className={`${styles.progressVal} ${Number(score).toFixed(0)!== Number(lastQuarterValue).toFixed(0)? Number(score).toFixed(0)   >  Number(lastQuarterValue).toFixed(0) ? 'text-color-green' : 'text-color-red': 'text-color-yellow'}`}>{`${Number(score).toFixed(0)}`}<span className={`${styles.chevron} `}><Chevron className={`accordion__icon ${Number(score).toFixed(0) < Number(lastQuarterValue).toFixed(0) ? styles.rotate : null}`} width={16} height={15} fill={"#000"} /></span></span>
                    }
                    
                    <span className={styles.indexDesc}>
                        {
                            Number(score).toFixed(0)-Number(lastQuarterValue).toFixed(0) ===0 ? <span className={styles.zeroPadding}>{``}</span> : Number(score).toFixed(0) > Number(lastQuarterValue).toFixed(0) ?<span>+</span> : <span>-</span>
                        }
                        {
                            <span>{`${Math.abs(difference.toFixed(0))}`}</span>
                        }
                        {/*<span>{Math.abs(Number(cxScore.score).toFixed(0) - Number(cxScore.lastQuarterValue).toFixed(0))}</span>*/}
                                {/*<span className={cxScore.score > cxScore.lastQuarterValue ? styles.arrowUp : styles.arrowDown}></span>*/}
                    </span>
                </div>
                {<div className={styles.kpiScoreTrendingChart}>
                    <CXBarChartSm 
                                id= {id}
                                height={30}
                                width={65}
                                keys={kpiKeys}
                                values={kpiValues}></CXBarChartSm>
                </div>}
                {/*<div className={styles.myProgress}>
                    <div className={cxScore.score >= cxScore.benchmark ? `${styles.myBar} bg-green` : `${styles.myBar} bg-red`} style={{ width: `${cxScore.score}%` }}></div>
                </div>*/}
                {/*
                    Number(cxScore.score).toFixed(0) === 0 ? <span className={styles.indexDesc}>No transactions in current quarter</span> :
                        Number(cxScore.score).toFixed(0) === Number(cxScore.lastQuarterValue).toFixed(0) ? <div className={styles.indexDesc}>
                            <span>Same as last quarter</span>
                            <span className={styles.arrowNoChange}></span>
                        </div>
                            :
                            <div className={styles.indexDescs}>
                                +<span>{Math.abs(Number(cxScore.score).toFixed(0) - Number(cxScore.lastQuarterValue).toFixed(0))}</span>
                                {<span className={cxScore.score > cxScore.lastQuarterValue ? styles.arrowUp : styles.arrowDown}></span>}
                            </div>*/
                }
            </div>
        </div>
    )
}
export default KPIScore;