import React from 'react'
import Chart from '../../chart/Chart';
import styles from "./revenuedistribution.module.scss";
import * as utils from "../../../utils/utils";

function RevenueDistribution(props) {

    const { id,name } = props
    let list = (props.list)?[...props.list]:[]
    let donutLabel = []
    let donutValues = []
    list.forEach(element => {
        element.revenueDiff = ((element.revenue - element.lastRevenue)
            / (element.revenue + element.lastRevenue)) * 100
        donutLabel.push(element.code || element.name)
        donutValues.push(element.revenue)
    });
    let donutData = [
        donutLabel,
        donutValues
    ];
    //console.log("donutValues",donutValues);
    //console.log("Sum of donutValues",donutValues.reduce((a, b) => a + b, 0))
    //let totalDonutValue = donutValues.reduce((a, b) => a + b, 0);

    let donutColorPattern = ["#9bd3de", "#b6bd01", "#f1b435", '#D5FC0F', '#874DE6', '#AF5733', '#B13AD2', '#F05203']
    // for (let i = 0; i < list.length; i++) {
    //     list[i]['colorCode'] = donutColorPattern[i]
    // }
    let arr = [];
    arr = [...donutColorPattern, ...donutColorPattern];
    for (let i = 0; i < list.length; i++) {
        list[i]['colorCode'] = arr[i];
    }
    return (
        <div className={styles.chartOuter}>
            <div className={styles.chartLeft}>
                <Chart
                id={id}
                    type="donut"
                    name={name}
                    data={donutData}
                    legendPosition="right"
                    pattern={donutColorPattern}
                />
            </div>
            <div className={styles.chartLegend}>
                <ul>
                    {
                        list.map((element, index) => (
                            <li key={index}>
                                <span
                                    className={styles.listDot}
                                    style={{ background: element.colorCode }}
                                ></span>
                                <span className={styles.labelName} title={`${element.name}`}>{element.name}</span>
                                $<span className={styles.labelValue} title={`${element.revenue.toFixed(0)}`}>{utils.convertToInternationalCurrencySystem(element.revenue.toFixed(0))}</span>
                                <span className={element.revenueDiff >= 0 ? styles.labelIncrease : styles.labelDecrease}>{!isNaN(element.revenueDiff)?Math.abs(element.revenueDiff).toFixed(0):0}%</span>
                                {
                                    element.revenueDiff >= 0 ?
                                        <span className={styles.labelUpArrow}></span> :
                                        <span className={styles.labelDownArrow}></span>
                                }
                            </li>
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default RevenueDistribution