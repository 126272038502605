import React from "react";
import styles from "../tiles.module.scss";
import Chevron from "../../../../components/chevron/Chevron";
import * as utils from '../../../../utils/utils';
import CXBarChartSm from '../../../../components/chart-sm/Chart-sm';

const CAS = (props) => {
    const { cas} = props;
    console.log("CAS--BOX",cas)
    const {score, lastQuarterValue, responseCount} = cas

    console.log("CAS--BOX--Score----",Number(score).toFixed(0));
    console.log("CAS--BOX--lastQuarterValue----",Number(lastQuarterValue).toFixed(0));

    //const [setActive, setActiveState] = useState("active");
    //const [setRotate, setRotateState] = useState("accordion__icon rotate");
//    setRotateState(
//     setActive === "active" ? "accordion__icon rotate" : "accordion__icon"
//   );

   const getSign = (score,lastQuarterValue)=>{
    if(Number(responseCount).toFixed(0)!== 0){
        if(Number(score).toFixed(0) > Number(lastQuarterValue).toFixed(0) )
         return '+'
         if(Number(score).toFixed(0) < Number(lastQuarterValue).toFixed(0) )
         return '-'
         if(Number(score).toFixed(0) === Number(lastQuarterValue).toFixed(0) )
         return '' 

    }

   }

   const getDifference = (score,lastQuarterValue)=>{
    
         if(Number(score).toFixed(0) === Number(lastQuarterValue).toFixed(0) )
         return true;
         else
         return false; 

    

   }

    return (
        <div
            className={styles.casCardContainer}
            onClick={() => props.viewScore('cas')}
        >
            <div className={styles.casCardHeader}>
                {/*<div className={styles.cardTitle}>CX Score</div>*/}
                { /*(responseCount !== undefined)?
                <div className={styles.nValue}>n = { utils.convertToInternationalCurrencySystem(responseCount)}</div>:''
    */}
            </div>
            <div className={styles.casCardBody}>
                <div className={styles.cardTitle}>CAS Score</div>
                <div className={styles.progressValBody}>
                    {
                        !responseCount ? `-` : <span className={`${styles.progressVal} ${(score!==lastQuarterValue) ? score > lastQuarterValue ? 'text-color-green' : 'text-color-red': 'text-color-yellow' }`}>{Number(score).toFixed(0)}<span className={`${styles.chevron} `}><Chevron className={`accordion__icon ${score < lastQuarterValue ? styles.rotate : null}`} width={15} fill={"#000"} /></span></span>
                    }
                    
                    <span className={styles.indexDesc}>
                        {
                          responseCount ? !getDifference(score,lastQuarterValue) ? <span>{`${getSign(score,lastQuarterValue)}`}</span>:<span className={styles.zeroPadding}></span>:<span></span>
                        }
                        {
                          responseCount? <span>{Math.abs(Number(score).toFixed(0) - Number(lastQuarterValue).toFixed(0))}</span>:<span></span>
                        }
                               
                    </span>
                </div>
                <div className={styles.casFooter}><span>{`CAS Surveys = ${responseCount}`}</span></div>
                {/*<div className={styles.cxScoreTrendingChart}>
                    <CXBarChartSm 
                                id= {10}
                                height={70}
                                width={100}
                                keys={cxKeys}
                                values={cxValues}></CXBarChartSm>
                    </div>*/}
                {/*<div className={styles.myProgress}>
                    <div className={cxScore.score >= cxScore.benchmark ? `${styles.myBar} bg-green` : `${styles.myBar} bg-red`} style={{ width: `${cxScore.score}%` }}></div>
                </div>*/}
                {/*
                    Number(cxScore.score).toFixed(0) === 0 ? <span className={styles.indexDesc}>No transactions in current quarter</span> :
                        Number(cxScore.score).toFixed(0) === Number(cxScore.lastQuarterValue).toFixed(0) ? <div className={styles.indexDesc}>
                            <span>Same as last quarter</span>
                            <span className={styles.arrowNoChange}></span>
                        </div>
                            :
                            <div className={styles.indexDescs}>
                                +<span>{Math.abs(Number(cxScore.score).toFixed(0) - Number(cxScore.lastQuarterValue).toFixed(0))}</span>
                                {<span className={cxScore.score > cxScore.lastQuarterValue ? styles.arrowUp : styles.arrowDown}></span>}
                            </div>*/
                }
            </div>
        </div>
    )
}
export default CAS;