import React from "react";
import c3 from "c3";
import d3 from "d3";

import "c3/c3.css";

const Chart = (props) => {
  //console.log("Chart Props",props);
  React.useEffect(() => {
    c3.chart.internal.fn.additionalConfig = {
      bar_radiusAll: false,
      bar_radius: 5,
      tooltip_format_color: undefined,
    };
    c3.generate({
      bindto: `#${props.name}`,
      size: {
        height: 200,
        width: 150,
      },
      data: {
        rows: props.data,
        type: props.type,
        labels: true,
     
      },
      donut: {
        width: 50,
        label: {
          format: function (value, ratio, id) {
            //console.log("Chart-format ratio", ratio);
            let label = Number.parseInt((ratio*100))/100;
            return d3.format('.0%')(label);
          },
       }
      },
      legend: {
        show: false,
      },
      color: {
        pattern: props.pattern,
      },
      axis: props.axisObj,
      bar: {
        space: 0.3,
      },
    });
  }, [props]);

  return (
    <div id={props.name} />
  );
};

export default Chart;
