import { connect } from 'react-redux'
import SubRegion from '../../pages/detailed-view/SubRegion'
import * as consts from '../../store/constant'
import favInput from '../../assets/json/user-favorites/input.json'


const mapStateToProps = state => {
    return {
        favInput: favInput,
        favorites: state.FavoriteReducer ? state.FavoriteReducer.data : null,

        selectedKPI: state.KPIReducer ? state.KPIReducer.selectedKPI : null,

        selectedSubRegion: state.SubRegionReducer ? state.SubRegionReducer.data : null,
        cxScoreDetails: state.KPIReducer ? state.KPIReducer.cxScoreDetails : null,
        breadCrumb: state.KPIReducer ? state.KPIReducer.breadCrumb : null,
        
        selectedProductDivision: state.ProductDivisionReducer ? state.ProductDivisionReducer.data : null,
        selectedSBS: state.SBSReducer ? state.SBSReducer.data : null,
        selectedCustomer: state.CustomerReducer ? state.CustomerReducer.data : null,
        sasParent: state.SASReducer ? state.SASReducer.data : null,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setKPI: (data) => dispatch({
            type: consts.SET_KPI,
            data: data
        }),
        setSelectedSBS: (data) => dispatch({
            type: consts.SET_SBS,
            data: data
        }),
        setProductDivision: (data) => dispatch({
            type: consts.SET_PRODUCT_DIVISION,
            data: data
        }),
        setCustomer: (data) => dispatch({
            type: consts.SET_CUSTOMER,
            data: data
        }),
        setCXScoreData: (data) => dispatch({
            type: consts.SET_CXSCORE_DATA,
            data: data
        }),
        setBreadCrumb: (data) => dispatch({
            type: consts.SET_BREAD_CRUMB,
            data: data
        }),
        setNBA: (data) => dispatch({
            type: consts.SET_NBA,
            data: data
        }),
        setBannerType: (data) => dispatch({
            type: consts.SET_BANNER_TYPE,
            data: data
        }),
        setNBABreadCrumb: (data) => dispatch({
            type: consts.SET_NBA_BREAD_CRUMB,
            data: data
        }),
        setSASParent: (data) => dispatch({
            type: consts.SET_SAS_PARENT,
            data: data
        }),
        setFavorite: (data) => dispatch({
            type: consts.SET_FAVORITE,
            data: data
        })
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubRegion)